.error-page {
  padding: 20px;
  color: #fff;
  text-align: center;
  h1 {
    font-size: 128px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  h3 {
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
  }
  .btn {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    &.btn-primary {
      border: none;
      background: none;
      &:hover,
      &:active,
      &:focus,
      &:visited,
      &.focus {
        border: none;
        background: none;
      }
    }
  }
}
