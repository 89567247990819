@import "../../scss/sfpvariables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$main-top: $navbar-height + $top-navigation-height + $side-margin;
$main-top-stick: $side-margin;

$main-top-is: $header-position == sticky;


//from custom
.main-content {
	padding-bottom: 20px !important;
	margin-bottom: 25px;
}

.main {
  header {
    position: $header-position;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindex-navbar + 2;
    width: 100%;
    padding-left: 0;
    width:100%;
    transform: translate3d(0, 0, 0);
    .logo {
      padding-left: $side-margin;
      width: $left-sidebar-width;
    }
    .navbar {
      width: 100%
    }
  }
  .main-content {
    padding-top: if($main-top-is, $main-top-stick, $main-top);
    padding-left: $side-margin;
    padding-right: $side-margin;
    transform: translate3d(0, 0, 0);
    .jumbotron {
      width: 100%;
      margin: 0;
    }
    > .row {
      margin-bottom: $side-margin;
      padding-bottom: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main {
      .header {
        padding-left: 0;
        padding-right: 0;
        position: $header-position-mobile;
        left: 0;
        .nav-inline-1,
        .nav-inline-2,
        .navbar-search-form {
          display: none;
        }
      }
      .main-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
        //padding-bottom: 0 !important;
        transform: translate3d(0, 0, 0);
        background-color: white;//MOBILE MAKE WHITE
        .shipping-status {
          display: none;
        }
        .jumbotron {
          .jumbotron-actions {
            display: none;
          }
        }
      }

  }
}
@include media-breakpoint-down(md) {
  .main {
      header {
        padding-left: 0;
        padding-right: 0;
        position: $header-position-mobile;
        left: 0;
        .nav-inline-2,
        .navbar-search-form {
          display: none;
        }
      }
      .main-content {
        padding-left: 0;
        transform: translate3d(0, 0, 0);
      }
  }
}
@include media-breakpoint-down(lg) {
  .main {
      header {
        padding-left: 0;
        padding-right: 0;
        left: 0;
        position: $header-position-mobile;
        .navbar-search-form {
          display: none;
        }
      }
      .main-content {
        padding-left: 0;
        transform: translate3d(0, 0, 0);
      }
    }
}
@include media-breakpoint-down(xl) {
  .main {
      header {
        padding-left: 0;
        padding-right: 0;
        left: 0;
        .navbar-search-form {
          display: none;
        }
        .logo {
          padding-left: 0;
          width: auto;
        }
      }
      .main-content {
        padding-left: $side-margin;
        transform: translate3d(0, 0, 0);
      }
  }
}
