
.empty-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: calc(100%);
  width: calc(100%);
  overflow: hidden;
  background: url(/assets/images/image11.jpg) no-repeat center center
    fixed;
  background-size: cover;
}

.home-view {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fafafa;
  position: relative;
  .main-content {
    padding-bottom:0!important;
    @media(max-width: 812px){
      margin-top: 200px;
    }
  }
}

//tbd top-navigation items