/*SFA subdomain class*/
.sfa {
  background-color: #ffad00 !important;
  color: rgb(0, 0, 0) !important;

  a, span, button  {
    color: rgb(0, 0, 0) !important;
    border-color: black !important;
  }

  .dropdown-inner, .list-item .list-item {
    background-color: #fff3e1 !important;
    color: black !important;
    .dropdown-item:hover{
      a, span, button  {
        color: #ffad00 !important;
      }

    }
  }
  .logo .title {
    font-weight: bold !important;
  }
}

.sfa-top-nav, .sfa-bottom-nav {
  background-color: #a7a9ac !important;
  color: black !important;
  font-weight: bold !important;
}

.sfa-bottom-nav {
  button {
    background-color: #a7a9ac !important;
  }
}

.sfa-modal {
  .modal-header {
    background-color: #ffad00 !important;
    color: black !important;
    h5 span {
      color: black !important;
    }
  }
  .modal-body {
    h3.text-primary {
      color: black !important;
    }
    h4.text-danger {
      color: #545456 !important;
    }
    .badge.bg-primary {
      background-color: #fec661 !important;
      color: black !important;
    }
    th {
      background-color: #ffad00 !important;
      color: black !important;
    }
    .ant-card-head {
      background-color: #fec661 !important;
      color: black !important;
    }
    .ant-alert-success {
      background-color: #caf3aa!important;
      color: black !important;
    }
    .ant-collapse-header {
      background-color: #ffe7bf !important;
      color: black !important;
      span {
        color: black !important;
      }
    }
    .badge.bg-success {
      background-color: #fec661 !important;
      color: black !important;
    }
    .btn-info {
      background-color: #ffad00 !important;
      color: black !important;
    }
  }

}
.sfa-content {
	.folders .btn {
		border: none;
		background-color: white!important;
	}
  .product-banner {
    background-color: #fff3e1 !important;
  }
  th {
    background-color: #fec661 !important;
    color: black !important;
  }
  td {
    a {
      color: #034f59 !important;
    }
  }
  .dashboard-widget th {
    color: white !important;
  }
  .card-header {
    background-color: #fec661 !important;
    border-color: black !important;
    color: black !important;
    h5 {
      color: black !important;
    }
  }

  .card-footer {
    background-color: #fec661 !important;
    border-color: black !important;
    color: black !important;
    h5 {
      color: black !important;
    }
  }

  .card-match.card {
    background-color: #fec661 !important;
  }

  .order-guide .float-right{
    .btn-info {
      background-color: #034f59 !important;
      color: white !important;
      .badge.bg-warning {
        background-color: white !important;
        color: black !important;
      }
    }
  }

    .ant-collapse-header {
      background-color: #ffe7bf !important;
    }
    .ant-collapse-borderless > .ant-collapse-item {
      border-bottom: 1px solid #000000;
    }
  /*   .badge {
      background-color: #fec661 !important;
      color: black !important;
    } */
    a.text-info {
      color: #034f59 !important;
    }
    h3.text-primary {
      color: black !important;
    }
    h4.text-danger {
      color: #545456 !important;
    }
    .badge.bg-primary {
      background-color: #fec661 !important;
      color: black !important;
    }
    .badge.bg-success {
      background-color: #fec661 !important;
      color: black !important;
    }

  .react-bootstrap-table-page-btns-ul {
    li.active {
      a {
        background-color: #fec661 !important;
        color: black !important;
        border-color: black !important;
      }

    }
  }
}
.sfa-content, .sfa-modal {
  //buttons
  .btn-primary, .brand-btn-primary, .brand-table-header-second {
    background-color: #fec661 !important;
    color: black !important;
    border-color: black !important;
  }
  .brand-btn-secondary {
    background-color: #ffad00 !important;
    color: black !important;
    border-color: black !important;
  }
	.btn-info {
		background-color: #034f59;
	}
  .brand-btn-link {
    background-color: #034f59 !important;
    color: white !important;
    border-color: black !important;
  }
  .brand-step-link{
    .ant-steps-item-content{
      .ant-steps-item-title, .ant-steps-item-description span {
        color: #034f59 !important;
      }
    }
  }
  .brand-btn-outline, .btn-outline {
    background-color: white !important;
    color: black !important;
    border-color: black !important;
		//font-size: 0.875em;
  }
  .brand-table-link, .onboarding-step-title {
    color: #034f59 !important;
  }
  .brand-table-header-primary {
    // background-color: #ffad00 !important;
    color: black !important;
    th {
      // background-color: #ffad00 !important;
      color: black !important;
    }
  }
  .alert-warning {
    strong {
      color: black !important;
    }
  }
  .brand-table-title{
    font-weight: bold;
  }
}

/*CHEX subdomain class*/
.chex {
  background-color: #337775!important;
  color: white !important;


  a, span, button  {
    color: white !important;
    border-color: white !important;
  }


  .dropdown-inner {
    background-color: #337775!important;
    color: white !important;
    .dropdown-item:hover{
      a, span, button  {
        color: #337775 !important;
      }

    }
  }
}
.agbr {
  background-color: #1c4c93ff!important;
  color: white !important;

  a, span, button  {
    color: white !important;
    border-color: white !important;
  }

  .dropdown-inner {
    background-color: #1c4c93ff!important;
    color: white !important;
    .dropdown-item:hover{
      a, span, button  {
        color: #1c4c93ff !important;
      }

    }
  }
}
.spartannash {
  background-color: #006850ff!important;
  color: white !important;

  a, span, button  {
    color: white !important;
    border-color: white !important;
  }

  .dropdown-inner {
    background-color: #006850ff!important;
    color: white !important;
    .dropdown-item:hover{
      a, span, button  {
        color: #006850ff !important;
      }

    }
  }
}
.ags {
  background-color: lightgray !important;
  color: black !important;
  a, span, button  {
    color: black !important;
    border-color: black !important;
  }

  .dropdown-inner {
    background-color: lightgray !important;
    color: black !important;
    .dropdown-item:hover{
      a, span, button  {
        color: black !important;
      }

    }
  }
}
[data-background="light"] .csspecialty .btn.btn-default { color: white!important; }
.csspecialty {
	background-color: #d2232a !important;
	.btn-primary {
		background-color: black !important;
		color: white !important;
	}
	.brand-btn-primary {
		background-color: black !important;
		color: white !important;
	}
	h4 {
		color: white !important;
	}
	.nav .badge-warning { background-color: black !important; }
}
.csspecialty-content {
	.btn-primary {
		background-color: black !important;
		border-color: black !important;
		color: white !important;
	}
	.brand-btn-primary {
		background-color: black !important;
		color: white !important;
		border-color: white !important;
	}
	.btn-warning, .btn-secondary {
		background: #939598 !important;
		color: white !important;
		border-color: #939598 !important;
	}
}
.csspecialty-modal {
	.modal-header {
		background-color: #d2232a !important;
	}
}
.dpi {
  background: #383838 !important;
  color: white !important;

  a, span, button  {
    color: white !important;
    border-color: white !important;
  }

  .dropdown-inner {
    background: #383838 !important;
    color: white !important;
    .dropdown-item:hover{
      a, span, button  {
        color: #383838 !important;
      }

    }
  }
}
.dcs {
  background-color: #CA3639 !important;
}
.bozzutos {
  background: #07095A !important;
  color: white !important;


  a, span, button  {
    color: white !important;
    border-color: white !important;
  }


  .dropdown-inner {
    background: #07095A !important;
    color: white !important;
    .dropdown-item:hover{
      a, span, button  {
        color: #07095A !important;
      }

    }
  }
}
.urm {
  background: #dc3c44 !important;
}
.pigdirect {
  //background: #ed1c24 !important;
  // background: #C9AC64 !important;
  background: #fdd0af!important;
  color: black !important;

  a, span, button  {
    color: rgb(0, 0, 0) !important;
    border-color: black !important;
  }

  .dropdown-inner, .list-item .list-item {
    // background-color: #C9AC64 !important;
    background-color: #fdd0af!important;
    color: black !important;
    .dropdown-item:hover{
      a, span, button  {
        // color: #C9AC64 !important;
        color: #fdd0af!important;
      }

    }
  }
}

.grocers {
  /* If there's a dropdown class set, this bg color will affect dropdown partially */
  /* Also works for Cart header and footer with a dropdown class set */
  background: #000!important;
  color: white !important;

  a, span, button  {
    color: rgb(255, 255, 255) !important;
    border-color: white !important;
  }

  /* Badge Icons */
  span.badge  {
    color: #000 !important;
    background-color: #f37324 !important;
  }

  /* General Hovering */
  .text-dark a.nav-link:hover,
  .nav-link .link-sfp-light a:hover,
  ul li a:hover,
  span.ant-dropdown-menu-title-content:hover,
  li.ant-dropdown-menu span:hover {
    color: #f37324!important;
  }

  /* User Dropdown Menu*/
  div.ant-dropdown-menu .bg-dark,
  .ant-dropdown-menu .bg-dark span,
  .ant-dropdown-menu .bg-dark a,
  .ant-dropdown-menu .bg-dark button {
      background-color: #000!important;
      color: white !important;
    }

  .ant-dropdown-menu .card-header {
    background-color: #000!important;
    color: white !important;
  }

  .dropdown-inner, .list-item .list-item {
    background-color: #000!important;
    color: white !important;
    .dropdown-item:hover, .ant-dropdown-menu-item:hover {
      a, span, button  {
        color: #f37324!important;
      }
    }
  }

    /*Logout button Hover*/
  .ant-dropdown-menu-item.dropdown-item:hover {
    span, i, a {
      color: #f37324!important;
    }
  }
}

.sfp {
	.brand-btn-outline {
		background-color: white;
		color: black!important;
		border-color: black;
		//font-size: 0.875em;
	}
}
