
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(/fonts/material-icons.woff) format("woff"),
    url(/fonts/material-icons.woff2) format("woff2");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}
.material-design-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .icon {
    text-align: center;
    width: 150px;
    min-height: 100px;
    padding: 20px 0;
    margin-bottom: 20px;
    i {
      margin: 0 5px;
    }
    .icons {
      display: block;
      margin-bottom: 20px;
    }
    .icon-class {
      display: block;
      font-size: 13px;
    }
  }
}
.material-icons {
  font-size: 16px;
  line-height: 16px;
  &.material-icons-1x {
    font-size: 20px;
    line-height: 20px;
  }
  &.material-icons-2x {
    font-size: 28px;
    line-height: 28px;
  }
  &.material-icons-3x {
    font-size: 40px;
    line-height: 40px;
  }
}

