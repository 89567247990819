@import "../sfpmixins";

$padding: 30px;
$margin: 1rem;
.sample-form {
  background-color: #fff;
  padding: $padding $padding + 10;
  @include box-shadow;
  h3 {
    @include font-size(17px);
    text-transform: uppercase;
    margin-bottom: calc($margin / 2);
    font-weight: normal;
  }
  .description {
    margin-bottom: $margin;
    @include font-size(15px);
  }
  label {
    font-weight: normal;
    margin-bottom: calc($margin / 2);
  }
  .input-group {
    margin-bottom: $margin;
  }
  textarea {
    height: 120px;
  }
}
