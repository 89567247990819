@import "sfpfunctions";


@mixin backdrop($z-index) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: $z-index;
  opacity: 0;
  background: #000;
	transition: opacity 0.25s ease-in-out;
  &.fade {
    display: block;
  }
  &.in {
    opacity: 0.35;
  }
}
@mixin box-shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
@mixin box-shadow-1 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
@mixin box-shadow-2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
@mixin box-shadow-3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
@mixin font-size($size) {
  //font-size: $size;
  font-size: calculate-rem($size);
}
@mixin sideline($width, $color) {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $width;
    height: 100%;
    background-color: $color;
  }
}
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}