@import "../sfpmixins";

.icon-widget-6 {
  padding: 10px;
  cursor: pointer;
  i {
    display: inline-flex;
    vertical-align: middle;
    font-size: 24px;
    color: #fff !important;
  }
  .text {
    white-space: nowrap;
    @include font-size(14px);
  }
  .number {
    @include font-size(16px);
    color: #fff !important;
  }
}
