@import "../sfpvariables";
@import "../sfpmixins";

 // deal sith bs classes instead
.mobile-navbar{
  @media(min-width: 993px){
    //display: none;
  }
  background-image: none;
}
